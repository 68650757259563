import { MAX_WIDTH } from "@/constants/components";
import { Box, Grid } from "@chakra-ui/react";
import {
  colors,
  getCdnImageSrc,
  Image,
  responsive,
  useResponsive,
  VStack,
} from "goi_common";

export default function CollaborationSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.gray100}
      css={responsive({
        padding: { base: "64px 0 72px", lg: "99px 0 88px" },
      })}
    >
      <VStack maxWidth={`${MAX_WIDTH}px`} width="100%" gap="40px">
        <Box className="gray900 subtitle_21_b lg_head_32_b" textAlign="center">
          고이는 중소벤처기업부, 서울대학교 등<br />
          신뢰도 높은 기관들과 함께 {isBiggerThanLg || <br />}
          상조 서비스를 혁신하고 있습니다.
        </Box>
        <VStack width="100%" alignItems="center">
          <Grid
            width="100%"
            css={responsive({
              gridRowGap: { base: "16px", lg: "20px" },
              gridColumnGap: { base: "4px", lg: "20px" },
              gridTemplateColumns: {
                base: "2fr 2fr 2fr",
                lg: "1fr 1fr 1fr 1fr 1fr 1fr",
              },
            })}
          >
            <GridItem id={1} text="중소벤처기업부" />
            <GridItem id={2} text="교보생명" />
            <GridItem id={3} text="국민은행" />
            <GridItem id={4} text="신용보증기금" />
            <GridItem id={5} text="카카오벤처스" />
            <GridItem id={6} text="서울대학교" />
          </Grid>
        </VStack>
      </VStack>
    </VStack>
  );
}

function GridItem({ id, text }: { id: number; text: string }) {
  return (
    <VStack alignItems="center" gap="8px" width="100%">
      <Box
        overflow="hidden"
        css={responsive({
          width: { base: "100px", lg: "100%" },
          borderRadius: { base: "100px", lg: "48px" },
        })}
      >
        <Image src={getCdnImageSrc(`direct/home/collabo-${id}.png`)} alt="" />
      </Box>
      <Box
        fontWeight={500}
        className="gray600"
        css={responsive({
          fontSize: { base: "14px", lg: "20px" },
        })}
      >
        {text}
      </Box>
    </VStack>
  );
}
