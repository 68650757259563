import {
  VStack,
  colors,
  LazyLoadYoutube,
  responsive,
  useResponsive,
} from "goi_common";

import { MAX_WIDTH } from "@/constants/components";
import { Box } from "@chakra-ui/react";

export default function TrustSection() {
  const { isBiggerThanMd } = useResponsive();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.warmGray100}
      css={responsive({
        padding: { base: "60px 0 30px", lg: "120px 0 60px" },
      })}
    >
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        padding="0 24px"
        width="100%"
        gap="16px"
      >
        <Box className="gray900 head_24_eb lg_head_42_b">장례를 바로 잡다.</Box>
        <Box
          className="gray500 subtitle_18_sb lg_head_32_b"
          css={responsive({
            padding: { base: 0, lg: "0 0 10px" },
          })}
        >
          <b className="gray900">고이</b>는 불투명한 장례 서비스를{" "}
          {isBiggerThanMd || <br />}
          IT 기술로 혁신하여
          <br />
          <b className="gray900">투명하고 정직한 장례 서비스</b>를 만들어 나가고
          있습니다.
        </Box>
        <LazyLoadYoutube src="https://www.youtube.com/embed/4HewyN5mJOg/" />
        <Box className="gray900 subtitle_18_b lg_head_28_b" marginTop="-10px">
          서울대 출신 장례 지도사가 IT로 시장을 혁신하는 법 | 송슬옹
          고이장례연구소 대표 인터뷰
        </Box>
      </VStack>
    </VStack>
  );
}
