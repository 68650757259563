import { getCdnImageSrc } from "goi_common";

export const prPress = [
  {
    title: "품목별 정찰제, 합리적 가격… 정직한 상조 기준을 세우다",
    press: "조선일보",
    date: "2024-08-16",
    href: "https://www.chosun.com/special/special_section/2024/08/16/3H2ZFMSVNJDKPG5X3SPGZGLUXE/",
    imgSrc: getCdnImageSrc("images/home/news-240820.png"),
  },
  {
    title:
      "고이장례연구소, ‘2024 대한민국 최고브랜드대상’ 장례 컨시어지 부문 대상",
    press: "이투뉴스",
    date: "2024-07-11",
    href: "https://www.e2news.com/news/articleView.html?idxno=310559",
    imgSrc: getCdnImageSrc("images/home/news-240711.png"),
  },
  {
    title: "고이장례연구소, 서울지방변호사회와 상조 서비스 제휴 협약 체결",
    press: "내외일보",
    date: "2024-07-10",
    href: "https://www.naewoeilbo.com/news/articleView.html?idxno=988429",
    imgSrc: getCdnImageSrc("images/home/news-240710.png"),
  },
  {
    title: '[인터뷰] 고이 "정보 비대칭 해소해 장례 생태계 바꾼다"',
    press: "데일리안",
    date: "2024-06-17",
    href: "https://dailian.co.kr/news/view/1372060",
    imgSrc: getCdnImageSrc("images/home/news2-240617.png"),
  },
  {
    title: "고이장례연구소, 월 100원 장례 준비 서비스 ‘100원 상조’ 출시",
    press: "매일경제",
    date: "2024-06-13",
    href: "https://www.mk.co.kr/news/business/11040550",
    imgSrc: getCdnImageSrc("images/home/news-240613.png"),
  },
  {
    title: "장례 스타트업 고이장례연구소, 선불식 할부 거래업 등록",
    press: "tech42",
    date: "2024-05-07",
    href: "https://www.tech42.co.kr/%EC%9E%A5%EB%A1%80-%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EA%B3%A0%EC%9D%B4%EC%9E%A5%EB%A1%80%EC%97%B0%EA%B5%AC%EC%86%8C-%EC%84%A0%EB%B6%88%EC%8B%9D-%ED%95%A0%EB%B6%80-%EA%B1%B0%EB%9E%98%EC%97%85/",
    imgSrc: getCdnImageSrc("images/home/news-240507.jpeg"),
  },
  {
    title:
      "유족 두번 울리는 '장례식 바가지' 없앤다…고이, 25억 유치 [고은이의 VC 투자노트]",
    press: "한국경제",
    date: "2024-04-18",
    href: "https://www.hankyung.com/article/202404180194i",
    imgSrc: getCdnImageSrc("images/home/news3-240426.png"),
  },
  {
    title: "[스타트업 발언대] '엄마아빠 장례, 특별하게 해주고 싶었죠'",
    press: "연합뉴스",
    date: "2023-06-16",
    href: "https://m.yna.co.kr/view/AKR20230615073300516?section=society/all",
    imgSrc: getCdnImageSrc("images/home/press-230615.jpeg"),
  },
  {
    title:
      "[스타트업 스토리] “2070년까지 성장하는 ‘장례 산업’, 고이가 혁신합니다”",
    press: "대한경제",
    date: "2023-06-29",
    href: "https://www.dnews.co.kr/uhtml/view.jsp?idxno=202306281243274900718",
    imgSrc: getCdnImageSrc("images/home/press-230701.png"),
  },
  {
    title: "스타트업 옷 입은 행복과 장례",
    press: "한국일보",
    date: "2023-04-25",
    href: "https://m.hankookilbo.com/News/Read/A2023042509590002982",
    imgSrc: getCdnImageSrc("images/home/press-230427.png"),
  },
  {
    title:
      "고이, 2023 대한민국 최고브랜드대상 '장례 컨시어지(상조 서비스)' 부문 대상 수상",
    press: "시선뉴스",
    date: "2023-11-16",
    href: "https://www.sisunnews.co.kr/news/articleView.html?idxno=203183",
    imgSrc: getCdnImageSrc("images/home/news1-240426.png"),
  },
  {
    title:
      "[2023 서울대학교 캠퍼스타운 스타트업 CEO] 장례 정보부터 장례지도사 매칭까지 제공하는 스타트업 ‘고이장례연구소’",
    press: "매거진한경",
    date: "2023-11-14",
    href: "https://magazine.hankyung.com/job-joy/article/202311146782d",
    imgSrc: getCdnImageSrc("images/home/news2-240426.png"),
  },
  {
    title:
      "송슬옹 고이장례연구소 대표 “스마트한 장례 서비스로 불투명 관행 싹 바꿀 것”",
    press: "한국경제",
    date: "2022-01-04",
    href: "https://www.hankyung.com/it/article/2022010456081",
    imgSrc: getCdnImageSrc("images/home/press-0.png"),
  },
  {
    title:
      "관악구·서울대학교 캠퍼스타운 입주기업 ‘고이장례연구소’ ‘(주)잘라컴퍼니’ 중기...",
    press: "한국경제",
    date: "2021-11-29",
    href: "https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=202111292334d",
    imgSrc: getCdnImageSrc("images/home/press-2.png"),
  },
  {
    title: "장례 플랫폼 고이, 교보생명 오픈 이노베이션 협업사 발탁",
    press: "한경닷컴",
    date: "2022-09-19",
    href: "https://www.hankyung.com/it/article/202209190529i",
    imgSrc: getCdnImageSrc("images/home/press-220919.png"),
  },
  {
    title: "맞춤형 장례 서비스하는 스타트업 ‘고이장례연구소'",
    press: "한경매거진",
    date: "2021-10-20",
    href: "https://magazine.hankyung.com/job-joy/article/202110209846d",
    imgSrc: getCdnImageSrc("images/home/press-4.png"),
  },

  {
    title: "“비싸고 다 똑같은 장례, ‘고이’가 바꾼다”",
    press: "지디넷코리아",
    date: "2021-12-13",
    href: "https://n.news.naver.com/article/092/0002242000",
    imgSrc: getCdnImageSrc("images/home/press-1.png"),
  },
  {
    title:
      "[인터뷰] 송슬옹 고이장례연구소 대표, “상조 업계의 정보 비대칭, 불투명한 관행…",
    press: "tech42",
    date: "2023-05-11",
    href: "https://www.tech42.co.kr/%EC%9D%B8%ED%84%B0%EB%B7%B0-%EC%86%A1%EC%8A%AC%EC%98%B9-%EA%B3%A0%EC%9D%B4%EC%9E%A5%EB%A1%80%EC%97%B0%EA%B5%AC%EC%86%8C-%EB%8C%80%ED%91%9C-%EC%83%81%EC%A1%B0-%EC%97%85%EA%B3%84%EC%9D%98/",
    imgSrc: getCdnImageSrc("images/home/press-230511.jpeg"),
  },
  {
    title:
      "전화상담 만족도 4.9/5.0점 고이, 양질의 장례 상담으로 누적 전화 상담건 급증",
    press: "넥스트유니콘",
    date: "2022-04-26",
    href: "https://www.nextunicorn.kr/content/be3bffb104017e56",
    imgSrc: getCdnImageSrc("images/home/press-20220426.png"),
  },

  {
    title: "고이장례연구소, 신용보증기금 혁신스타트업 성장지원 프로그램 선정",
    press: "스타트업엔",
    date: "2022-11-18",
    href: "https://www.startupn.kr/news/articleView.html?idxno=30573",
    imgSrc: getCdnImageSrc("images/home/press-221118.png"),
  },

  {
    title: "고이, 2021 대한민국 최고브랜드대상 ‘장례 컨시어지' 부문 대상",
    press: "세계비즈",
    date: "2021-07-23",
    href: "http://www.segyebiz.com/newsView/20210723504585",
    imgSrc: getCdnImageSrc("images/home/press-3.png"),
  },

  {
    title:
      "통합 장례 플랫폼 ‘고이장례연구소', 카카오벤처스로부터 4억원 투자 유치",
    press: "플래텀",
    date: "2021-09-07",
    href: "https://platum.kr/archives/170708",
    imgSrc: getCdnImageSrc("images/home/press-5.png"),
  },
];
