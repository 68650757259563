import { MAX_WIDTH } from "@/constants/components";
import { Box, Stack } from "@chakra-ui/react";
import {
  colors,
  getCdnImageSrc,
  Image,
  responsive,
  useResponsive,
  VStack,
} from "goi_common";
import { ReactNode } from "react";

export default function NumberOneSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.warmGray100}
      css={responsive({
        padding: { base: "64px 0", lg: "120px 0" },
      })}
    >
      <VStack maxWidth={`${MAX_WIDTH}px`} padding="0 24px" width="100%">
        <Box
          className="gray900 head_24_eb lg_head_42_b"
          css={responsive({
            paddingBottom: { base: "32px", lg: "64px" },
          })}
        >
          <span className="orange650">18만 명</span>의 회원이 이용한
          {isBiggerThanLg || <br />} 투명하고 정직한 장례 서비스,
          <br />
          걱정말고 가입해보세요.
        </Box>

        <Stack
          css={responsive({
            flexDirection: { base: "column", lg: "row" },
            gap: { base: "12px", lg: "24px" },
          })}
        >
          <NumberOneCard
            imgSrc="1"
            title={
              <Box
                css={responsive({
                  fontSize: { base: "24px", lg: "40px" },
                })}
                fontWeight={600}
              >
                180,000
                <span
                  css={responsive({
                    fontSize: { base: "24px", lg: "33px" },
                    fontWeight: 600,
                  })}
                >
                  명
                </span>
              </Box>
            }
            description={
              <>
                4년 연속 <b>서비스 혁신 1위, 브랜드 1위,</b>
                <br />
                상조를 혁신하고 있습니다.
              </>
            }
          />
          <NumberOneCard
            imgSrc="2"
            title={
              <Box
                css={responsive({
                  fontSize: { base: "24px", lg: "40px" },
                  fontWeight: 600,
                })}
              >
                4.9
                <span
                  css={responsive({
                    fontSize: { base: "24px", lg: "33px" },
                  })}
                >
                  점
                </span>
              </Box>
            }
            description={
              <>
                <b>고객 만족도 1위,</b>
                <br />
                투명함과 정직함으로 사랑받고 있습니다.
              </>
            }
          />
          <NumberOneCard
            imgSrc="3"
            title={
              <Box
                css={responsive({
                  fontSize: { base: "24px", lg: "40px" },

                  fontWeight: 600,
                })}
              >
                4,350,000,000
                <span
                  css={responsive({
                    fontSize: { base: "24px", lg: "33px" },
                  })}
                >
                  원
                </span>
              </Box>
            }
            description={
              <>
                <b>업계 최초 벤처 투자 유치,</b>
                <br />
                탄탄한 자본으로 운영하고 있습니다.
              </>
            }
          />
        </Stack>
      </VStack>
    </VStack>
  );
}

function NumberOneCard({
  imgSrc,
  title,
  description,
}: {
  imgSrc: string;
  title: ReactNode;
  description: ReactNode;
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <Stack
      css={responsive({
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);",
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.white,
        width: { base: "100%", lg: "33%" },
        alignItems: { base: "flex-start", lg: "center" },
        justifyContent: { base: "flex-start", lg: "initial" },
        padding: { base: "16px 20px", lg: "40px 0" },
        borderRadius: { base: "16px", lg: "32px" },
        flexDirection: { base: "column-reverse", lg: "column" },
      })}
    >
      <Box
        bottom={0}
        right={0}
        css={responsive({
          position: { base: "absolute", lg: "relative" },
        })}
      >
        <Image
          src={getCdnImageSrc(
            `direct/home/numberone-${isBiggerThanLg ? "" : "m-"}${imgSrc}.png`
          )}
          alt=""
          css={responsive({
            width: { base: "110px", lg: "190px" },
            height: { base: "110px", lg: "190px" },
          })}
        />
      </Box>
      <VStack
        css={responsive({
          alignItems: { base: "flex-start", lg: "center" },
        })}
      >
        <Box
          className="orange650"
          css={responsive({
            padding: { base: 0, lg: "48px 0 24px" },
          })}
        >
          {title}
        </Box>
        <Box
          className="gray800 body_14_m lg_subtitle_21_r"
          css={responsive({
            textAlign: { base: "left", lg: "center" },
            paddingBottom: { base: "65px", lg: 0 },
          })}
        >
          {description}
        </Box>
      </VStack>
    </Stack>
  );
}
