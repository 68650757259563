import {
  VStack,
  HStack,
  Image,
  NextLink,
  customEvent,
  colors,
  responsive,
} from "goi_common";
import { MAX_WIDTH } from "@/constants/components";
import { Box } from "@chakra-ui/react";
import { prPress } from "@/constants/prPress";

export default function PRSection() {
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.gray100}
      css={responsive({
        paddingBottom: { base: "60px", lg: "100px" },
      })}
    >
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        width="100%"
        css={responsive({
          paddingBottom: { base: "40px", lg: "60px" },
        })}
      >
        <Box className="gray900 subtitle_21_b lg_head_32_b" textAlign="center">
          혁신성과 차별성으로
          <br />
          언론에서도 고이를 주목하고 있습니다.
        </Box>
      </VStack>

      <HStack
        overflow="scroll"
        css={{
          [`::-webkit-scrollbar`]: {
            display: "none",
          },
        }}
        maxWidth="100vw"
      >
        <HStack
          gap={10}
          padding="0 20px"
          onClick={() => {
            customEvent({
              newGtm: {
                path: "service",
                trigger_and_target: "pr-uv_pr",
              },
            });
          }}
        >
          {prPress.map((p, i) => (
            <PressItem
              key={p.title}
              src={p.imgSrc}
              title={p.title}
              press={p.press}
              date={p.date}
              href={p.href}
            />
          ))}
        </HStack>
      </HStack>
    </VStack>
  );
}

interface PressItemProps {
  src: string;
  title: string;
  press: string;
  date: string;
  href: string;
}

const PressItem = ({ src, title, press, date, href }: PressItemProps) => {
  return (
    <NextLink href={href} onClick={() => {}}>
      <VStack gap={8} width="280px">
        <Box
          borderRadius="5px"
          marginBottom="8px"
          height="155px"
          overflow="hidden"
        >
          <Image src={src} width="100%" height="auto" alt="" />
        </Box>
        <h4 className="button gray900" css={{ minHeight: "48px" }}>
          {title}
        </h4>
        <HStack gap={22} className="caption1 gray700" css={{ fontWeight: 300 }}>
          <span>{press}</span>
          <span>{date}</span>
        </HStack>
      </VStack>
    </NextLink>
  );
};
